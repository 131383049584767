import React, { useEffect, useRef, useState } from "react";
import { Layout, Form, ButtonLoading, useQueryLoad } from "../components";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet";
import {
  Paper,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  FormGroup,
  Checkbox,
  Switch,
  Typography,
} from "@material-ui/core";
import { formTools } from "../common/utils";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  AddCcpaQueue,
  AddCcpaQueueVariables,
} from "../common/models/types/AddCcpaQueue";
import { REQUEST, CPA_RESPONSE } from "../common/models";
import { useModal } from "../components/modal";
import styled from "styled-components";
import { styles } from "../styles";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import { useFormHelpers } from "../common/hooks/formHooks";
import { FormComp } from "../components/form";
import { RouteComponentProps } from "@reach/router";
import { parse } from "query-string";
import {
  GetCpaResponse_CpaResponse,
  GetCpaResponse,
} from "../common/models/types/GetCpaResponse";
import {
  CcpaQueueInputType,
  RequestTypeEnum,
} from "../types/graphql-global-types";

interface Props {}

const emptyEntry: CcpaQueueInputType = {
  Email: "",
  FirstName: "",
  LastName: "",
  RequestType: RequestTypeEnum.Info,
  ZipCode: "",
  PhoneNumber: "",
  Address: "",
  StateCode: "",
  IsRightRequest: true,
  IsDeleteRequest: false,
  IsSoldRequest: false,
};

const Login = (props: Props & RouteComponentProps) => {
  const form = useForm({ mode: "onBlur" });

  const { register, setValue, handleSubmit, reset, getValues, watch } = form;

  const queryParams =
    typeof window !== "undefined" ? parse(location.search) : { referrer: "" };

  const formHelpers = useFormHelpers();

  const { Modal, closeModal, openModal, setChildren } = useModal();

  const [verification, setVerification] = useState("");
  const [referrer, setReferrer] = useState(
    typeof window !== "undefined" && document?.referrer
      ? String(document?.referrer || "")
      : String(queryParams.referrer || "")
  );

  const { executeRecaptcha } = useGoogleReCaptcha();
  executeRecaptcha?.("ccpa").then(r => {
    setValue("Verification", r);
    setVerification(r);
  });

  const [submitForm, { loading }] = useMutation<
    AddCcpaQueue,
    AddCcpaQueueVariables
  >(REQUEST);

  const [
    getCpaResponse,
    {
      data: cpaResponseData,
      error: cpaResponseError,
      loading: cpaResponseLoading,
    },
  ] = useLazyQuery<GetCpaResponse>(CPA_RESPONSE);

  const resetForm = () => {
    const allKeys = Object.keys(getValues()).reduce(
      (a, c) => ({ ...a, [c]: "" }),
      {}
    );
    //console.log(allKeys);
    reset(allKeys);
    setFormData(emptyEntry);
  };

  const [formData, setFormData] = useState<CcpaQueueInputType>(emptyEntry);

  const singleRequestCheck = (reqType: "Info" | "Delete" | "Sell") => {
    const newFormData: CcpaQueueInputType = {
      ...formData,
      IsRightRequest: reqType === "Info",
      IsDeleteRequest: reqType === "Delete",
      IsSoldRequest: reqType === "Sell",
    };
    setFormData(newFormData);
  };

  const onSubmit = (d: any, e: any) => {
    e.preventDefault();
    const { Verification, ...input } = d;

    if (formData.IsRightRequest && !["CA", "CO", "CT", "UT", "VA"].includes(input.StateCode)) {
      const allowedStates = ["CA", "CO", "CT", "UT", "VA"]
        ?.map(key => (key === "VA" ? "and " : "") + stateNames[key])
        ?.join(", ");      

      return toast.error(
        `Unable to submit request. Only state ${allowedStates} is allowed.`
      );
    }
    //console.log("Submit called");

    // if (input.IsRightRequest) {
    //   input.RequestType = RequestTypeEnum.Info;
    // } else if (input.IsDeleteRequest) {
    //   input.RequestType = RequestTypeEnum.Delete;
    // } else if (input.IsSoldRequest) {
    //   input.RequestType = RequestTypeEnum.Sell;
    // }

    input.Referrer = referrer || "";

    if (formData.IsRightRequest) {
      input.Info = RequestTypeEnum.Info;
      input.RequestType = RequestTypeEnum.Info;
      input.Status = 2;
    }

    if (formData.IsDeleteRequest) {
      input.Info = RequestTypeEnum.Delete;
      input.RequestType = RequestTypeEnum.Delete;
      input.Status = 1;
    }

    if (formData.IsSoldRequest) {
      input.Info = RequestTypeEnum.Sell;
      input.RequestType = RequestTypeEnum.Sell;
      input.Status = 3;
    }

    submitForm({
      variables: { input, Verification: Verification || verification },
    })
      .then(r => {
        //console.log("Response", r);
        let whereRequestType = r.data?.AddCcpaQueue?.RequestType;
        // let whereStateCode =
        //   r.data?.AddCcpaQueue?.StateCode !== "CA" &&
        //   r.data?.AddCcpaQueue?.StateCode !== "NV" &&
        //   r.data?.AddCcpaQueue?.StateCode !== "CO"
        //     ? "NN"
        //     : r.data?.AddCcpaQueue?.StateCode;

        let whereStateCode = !["CA", "CO", "CT", "UT", "VA"].includes(
          r.data?.AddCcpaQueue?.StateCode || ""
        )
          ? "NN"
          : r.data?.AddCcpaQueue?.StateCode;
        const where = `ResponseType = "${whereRequestType}" and StateCode = "${whereStateCode}"`;

        try {
          getCpaResponse({ variables: { where } });
        } catch (error) {
          console.log(error);
          if (error) {
            toast.error(
              "There was an issue submitting your request. please try again"
            );
          }
        }
      })
      .catch(e => {
        console.log(
          "Apologies, there was an error submitting your request. Please try again in a few minutes"
        );
        toast.error(
          "Apologies, there was an error submitting your request. Please try again in a few minutes"
        );
      });
  };

  useEffect(() => {
    if (cpaResponseData) {
      // console.log("cpaResponseData", cpaResponseData);
      if (cpaResponseData?.CpaResponse?.length > 0) {
        setChildren(
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>Thank You</h3>
            <p>{cpaResponseData?.CpaResponse[0].ResponseText}</p>
            <S.CloseButton
              onClick={() => {
                if (cpaResponseData?.CpaResponse[0].ResponseType == "Delete") {
                  setTimeout(function() {
                    resetForm();
                    setValue("Verification", "Info");
                    setChildren(
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <h3>Thank You</h3>
                        <p>
                          Thank you for confirming. We will be responding
                          shortly.
                        </p>
                        <S.CloseButton
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          Close
                        </S.CloseButton>
                      </div>
                    );
                    openModal();
                  }, 2000);
                }
                resetForm();
                closeModal();
              }}
            >
              {cpaResponseData?.CpaResponse[0]?.ButtonText || "Close"}
            </S.CloseButton>
          </div>
        );
      } else {
        setChildren(
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3>Thank You</h3>
            <p>Thank you for confirming. We will be responding shortly.</p>
            <S.CloseButton
              onClick={() => {
                resetForm();
                closeModal();
              }}
            >
              Close
            </S.CloseButton>
          </div>
        );
        toast.error("No thank you message for your request");
      }

      openModal();
    }
  }, [cpaResponseData]);

  useEffect(() => {
    //console.log("Version: " + process.env.GATSBY_VERSION);
    if (!!window) {
      //console.log("Referrer: " + (document.referrer || queryParams.referrer));
    }
    register({ name: "Verification" });
    //console.log("loading...");
  }, []);

  const InputLabelProps = {
    style: {
      color: styles.color.grey_d2,
    },
  };

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>Secure Consumer Info</title>
      </Helmet>
      <Paper
        style={{
          display: "flex",
          height: "fit-content",
          marginTop: "20px",
        }}
      >
        <Form
          style={{
            padding: "30px",
            width: "90vw",
            maxWidth: "960px",
          }}
        >
          {/* {typeof window !== "undefined" &&
          (document?.referrer || queryParams.referrer) ? ( */}
          {/* <FormComp.Input
            hidden
            name="Referrer"
            key={"InputReferrer"}
            form={form}
            defaultValue={
              (typeof window !== "undefined" && document?.referrer) ? String(document?.referrer) : (String(queryParams.referrer) || "")
            }
          /> */}

          <FormComp.Input
            hidden
            name="RequestType"
            key={"InputRequestType"}
            form={form}
            defaultValue={RequestTypeEnum.Info}
            InputProps={{
              value: formData.RequestType || RequestTypeEnum.Info,
              InputLabelProps,
            }}
          />
          <FormComp.Input
            name="FirstName"
            key={"InputFirstName"}
            label="First Name"
            form={form}
            ControllerProps={{
              rules: { required: true },
            }}
            InputProps={{
              value: formData.FirstName || "",
              InputLabelProps,
            }}
          />
          <FormComp.Input
            name="LastName"
            key={"InputLastName"}
            label="Last Name"
            form={form}
            ControllerProps={{
              rules: { required: true },
            }}
            InputProps={{
              value: formData.LastName || "",
              InputLabelProps,
            }}
          />
          <FormComp.Input
            name="Email"
            label="Email Address"
            form={form}
            ControllerProps={{
              rules: { required: true, pattern: formTools.EmailPattern },
            }}
            InputProps={{
              value: formData.Email || "",
              InputLabelProps,
            }}
          />
          <FormComp.Input
            name="ZipCode"
            label="ZIP Code"
            form={form}
            ControllerProps={{
              rules: { required: true, pattern: formTools.ZipCodePattern },
              onChange: (e: any) => {
                return e[0]?.target?.value.replace(/\D/gi, "");
              },
            }}
            InputProps={{
              value: formData.ZipCode || "",
              InputLabelProps,
            }}
          />
          <FormComp.Input
            name="PhoneNumber"
            label="Phone Number"
            form={form}
            InputProps={{ InputLabelProps }}
          />
          <FormComp.Input
            name="Address"
            label="Street Address"
            form={form}
            InputProps={{ InputLabelProps }}
          />
          <FormComp.Select
            name="StateCode"
            label="State of Residence"
            ControllerProps={{
              rules: { required: true },
            }}
            options={Object.entries(stateNames).map(([value, label]) => ({
              label,
              value,
            }))}
            form={form}
            formHelpers={formHelpers}
            InputLabelProps={{ ...InputLabelProps }}
            InputProps={{
              value: watch("StateCode"),
            }}
          />
          <Typography variant="h2">Select Request Type:</Typography>
          <FormComp.Switch
            name="IsRightRequest"
            key="IsRightRequest"
            checked={!!formData.IsRightRequest}
            InputProps={{
              onClick: (evt: React.MouseEvent) => {
                // if (!formData.IsRightRequest) {
                singleRequestCheck("Info");
                // } else {
                //   evt.preventDefault();
                // }
              },
            }}
            label="Right to Know"
            form={form}
          />
          <FormComp.Switch
            name="IsDeleteRequest"
            key="IsDeleteRequest"
            checked={!!formData.IsDeleteRequest}
            InputProps={{
              onClick: (evt: React.MouseEvent) => {
                // if (!formData.IsDeleteRequest) {
                singleRequestCheck("Delete");
                // } else {
                //   evt.preventDefault();
                // }
              },
            }}
            label="Right to Delete"
            form={form}
          />
          <FormComp.Switch
            name="IsSoldRequest"
            key="IsSoldRequest"
            checked={!!formData.IsSoldRequest}
            InputProps={{
              onClick: (evt: React.MouseEvent) => {
                // if (!formData.IsSoldRequest) {
                singleRequestCheck("Sell");
                // } else {
                //   evt.preventDefault();
                // }
              },
            }}
            label="Do Not Sell My Information"
            form={form}
          />
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? <ButtonLoading /> : "Submit Request"}
          </button>
        </Form>
      </Paper>
      <Modal />
    </Layout>
  );
};

export default Login;

const S = {
  CloseButton: styled.button`
    ${styles.button.base};
    ${styles.button.primary};
    margin-top: 1em;
  `,
  HiddenInput: styled.input`
    display: none;
  `,
};

const stateNames = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FM: "Federated States of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KT: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
